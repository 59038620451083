const Video = ({pageData, videoURL}) => {
    return (
        <div className={pageData.is_full_width ? 'col-sm-12' : 'col-sm-6' }>
            <div className="video-container">
                <iframe className="video-wrapper" width="100%" height="100%" src={videoURL+'?rel=0'} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default Video
