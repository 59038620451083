import axios from 'axios';

// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = (path) => {
  return new Promise((resolve, reject) => {
    axios.get(`${path}`)
      .then(response => { 
        resolve(response) 
      })
      .catch(error => { reject(window.alert(error.response)) });
  });
};
// HTTP PUT Request - Returns Resolved or Rejected Promise
export const put = (path, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`${path}`, data)
      .then(response => {
        resolve(response) 
      })
      .catch(error => { reject(window.alert(error.response)) });
  });
};
// HTTP POST Request - Returns Resolved or Rejected Promise
export const post = (path, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${path}`, data)
      .then(response => {
        resolve(response) 
      })
      .catch(error => { reject(window.alert(error.response)) });
  });
};
// HTTP DELETE Request - Returns Resolved or Rejected Promise
export const del = (path) => {
  return new Promise((resolve, reject) => {
    if (!window.confirm('Are you sure you wish to delete this item?')) return;
    axios.delete(`${path}`)
      .then(response => { 
        resolve(response) })
      .catch(error => { reject(window.alert(error.response)) });
  });
};