import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Redirect, Switch, Route} from 'react-router-dom'

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/lv" />
        </Route>
        <Route path="/:locale">
          <App />
        </Route>
      </Switch>
    </Router>, rootElement);
} else {
  render(
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/lv" />
        </Route>
        <Route path="/:locale">
          <App />
        </Route>
      </Switch>
    </Router>, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
