// import './Navbar.scss'
import Logo from '../../../assets/img/logo_new.png'
import SocialIcons from '../social-icons/SocialIcons'
import LanguageSelector from './LanguageSelector'
import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'

const Navbar = ({ locales, content, pageNavbar, currentLocale, onToggleMobileMenu, isMenuOpen, hasScrollClass }) => {
    let { slug, parentslug } = useParams()
    console.log("Scroll class: ", hasScrollClass)
    return (
        <>
            <nav className={`navbar navbar-fixed-top ${!isMenuOpen ? "collapsed" : ""} ${!isMenuOpen && hasScrollClass ? "dimm" : ""} `}>
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className={`navbar-toggle ${!isMenuOpen ? "collapsed" : ""}`}
                            data-toggle="collapse"
                            data-target="#navbar-collapse"
                            aria-expanded={isMenuOpen}
                            onClick={onToggleMobileMenu}
                            >+</button>
                        <Link className="navbar-brand" to={`/${currentLocale}`}>
                            <img src={Logo} alt="cdp" />
                        </Link>
                    </div>
                    
                    <div className={`collapse navbar-collapse ${isMenuOpen ? "in" : ""}`} id="navbar-collapse">
                        <div className="language-selector desktop">
                            <LanguageSelector locales={locales} currentLocale={currentLocale}/>
                        </div>

                        <div className="social-links desktop">
                            <SocialIcons />
                        </div>
                        

                        <ul className="nav navbar-nav navbar-right">
                            {pageNavbar.menu.map((navbarItem, index) => (
                                !navbarItem.page.is_home &&
                                    <li key={index} onClick={onToggleMobileMenu} 
                                        className={`${navbarItem.page.category && 
                                            navbarItem.page.slug === parentslug ||
                                            navbarItem.page.slug === slug  ? "active" : ""}`}>
                                        <Link to={`/${navbarItem.page.locale}/${navbarItem.page.slug}`}>{navbarItem.page.name}</Link>
                                    </li>
                            ))}
                        </ul>

                        <div className="social-links mobile">
                            <SocialIcons />
                        </div>

                        <div className="language-selector mobile">
                            <LanguageSelector locales={locales} currentLocale={currentLocale}/>
                        </div>
                    </div>
                </div>
            </nav>
        </>
        

    )
}

Navbar.defaultProps = {
    pages: 'Not defined values'
}

export default Navbar
