import { Parallax } from 'react-scroll-parallax';
import { getBackgroundImage } from '../../../utils/pageUtils'

const Image = ({ pageData, imageData, isSingleImage }) => {
    const { media, is_full_width, has_page_title } = imageData
    const backgroundImageStyle = {backgroundImage: 'url("' + getBackgroundImage(media, is_full_width) + '")'}
    const mobileImageSrc = getBackgroundImage(media, is_full_width)
    const fullWidthImageSrc = getBackgroundImage(media, is_full_width)

    return (
        <div className={is_full_width ? 'col-sm-12' : 'col-sm-6' }>
            <div className="img-wrapper">
                {media?.length < 0 && 
                    <img src="http://placehold.it/384x256&text=3x2"/>
                }
                <div className="mobile-image-container">
                    <img className="mobile-image" src={mobileImageSrc} />
                    {has_page_title && <h1 className="title">{pageData.name}</h1>} 
                </div>
                {is_full_width 
                    ? (
                    <>
                        {/* <Parallax disabled={false} bgImage={fullWidthImageSrc} bgImageAlt={media.alternativeText} strength={150}></Parallax> */}
                        <section className='cover fullscreen-block'>
                            <img src={fullWidthImageSrc} />
                            {has_page_title && <h1 className="title">{pageData.name}</h1>} 
                        </section>
                    </>) 
                    : (
                        isSingleImage 
                        ? <div className="cover single-image img-container ratio-3x2" >
                            <img src={fullWidthImageSrc} />
                            {has_page_title && <h1 className="title">{pageData.name}</h1>} 
                        </div> 
                        :
                        <>
                            <div className='cover img-container ratio-3x2'>
                                <img src={fullWidthImageSrc} className="img-responsive" />
                                {has_page_title && <h1 className="title">{pageData.name}</h1>}
                            </div>
                        </> 
                    )
                }
            </div>
        </div>
        
    )
}

export default Image
