import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.scss';
SwiperCore.use([EffectFade, Navigation, Autoplay]);

const Slider = ({slides}) => {
    const adminUrl = `${process.env.REACT_APP_CDP_API_URL}`
    console.log("slider: ", slides)
    return (
        
        <Swiper
            effect="fade"
            autoplay={{ delay: 15000 }}
            slidesPerView={1}
            loop={true}
            navigation
            pagination={{ clickable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <div className="image" style={{backgroundImage: 'url("' + adminUrl + slide.url + '")'}}></div>
                    <img
                        src={adminUrl + slide.formats.large.url}
                        className="img-responsive"
                        alt={slide.alternativeText}/>
                    
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default Slider
