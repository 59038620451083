import { useCallback, useState } from 'react'
import { Marker } from '@react-google-maps/api';
import { GoogleMap, useJsApiLoader, useLoadScript } from '@react-google-maps/api'

const Map = ({ pageData }) => {
    const libraries = ['places']
    const mapContainerStyle = {
        width: '100vw',
        height: '55vh'
    }
    const center = {
        lat: pageData.lat,
        lng: pageData.lng
    }
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries
    })

    if (loadError) return 'Error'

    return isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={14}
        >
            <Marker position={center} />
        </GoogleMap>
    ) : <></>
}

export default Map
