import { useState } from 'react'

const SocialIcons = () => {
    const social = [
        { icon: 'fa-instagram', url: 'https://www.instagram.com/creativedesignpeople/' },
        { icon: 'fa-pinterest-p', url: 'https://www.pinterest.com/cdp1595/' },
        { icon: 'fa-facebook', url: 'https://www.facebook.com/creativedesignpeople' }
    ]

    const [socialData, setSocialData] = useState(social)

    return (
        <div>
            <div className="social-links">
                {socialData.map(( socialDataRow, index ) => (
                    <a href={ socialDataRow.url } target="_blank" key={index}>
                        <i className={'fa ' + socialDataRow.icon}></i>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default SocialIcons
