import { Link } from 'react-router-dom'

const LanguageSelector = ({ locales, currentLocale }) => {
    return (
        <>
            <div className="current-language-container">
                <a className="current-language">{currentLocale}</a>
                <div className="language-arrow"></div>
            </div>     
            <div className="language-selector-wrapper">
                {locales.map((locale, index) => (
                    locale.locale !== 'ru' && <Link key={index} to={"/"+locale.locale}>{ locale.locale }</Link>
                ))}
            </div>
        </>
    )
}

export default LanguageSelector
