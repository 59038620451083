import Slider from '../components/blocks/slider/Slider'

export const localiseContent = (content) => {
    const page = content.map(page => {
        const blocks = page.content_blocks.map(block => {
            if(block.locale === 1) {
                return block
            }
        })
        return page, blocks
    })
    return page
}

export const filterPublishedContent = (content) => {
    const publishedContent = content.filter(page => page.is_published || page.is_published === null)

    return publishedContent
}

export const orderArrayByProperty = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const getPage = (content, slug) => {
    const page = content.filter(page => page.slug === slug)
    
    return page
}

export const returnComponent = (component) => {
    const componentCollection = [{
        'block-type.image-text' : <Slider />
    }]
    const chosen = componentCollection.map(collectionItem => {
        console.log("collection: ", collectionItem, component)
        return collectionItem.component
    })
    console.log("chosen: ", chosen)
    return chosen
}

export const getBackgroundImage = (image, isLarge) => {
    const adminUrl = `${process.env.REACT_APP_CDP_API_URL}`
    const placeholderUrl = 'https://trirama.com/wp-content/uploads/2016/10/orionthemes-placeholder-image.png'
    let imageSize = placeholderUrl

    if(!image) {
        return imageSize
    } 

    if(!image.formats || image === undefined) {
        return imageSize
    }
    // console.log("is image!", isLarge, image)
    if(!isLarge || isLarge === undefined) {
        // console.log("is not large image!", isLarge, image.formats)
        return imageSize =  
            (image.formats.medium?.url) ? 
                adminUrl + image.formats.medium.url 
            : (image.formats.small?.url) ? 
                adminUrl + image.formats.small.url 
            : placeholderUrl
    }
    // console.log("is large image!", isLarge, image)
    return imageSize = (image?.url) ? adminUrl + image.url : (image.formats.large?.url) ? adminUrl + image.formats.large.url : placeholderUrl
    
}

export const shuffleArray = (array) => {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }