import './Footer.scss'
import SocialIcons from '../social-icons/SocialIcons'

const Footer = () => {
    return (
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <span className="copyright">&copy; {new Date().getFullYear()} SIA CDP</span>
                        <SocialIcons />
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer
