import ReactMarkdown from "react-markdown";


const Text = ({ textData }) => {
    const { is_full_width } = textData
    return (
        <div className={is_full_width ? 'col-sm-12' : 'col-sm-6' }>
            <div className="text">
                <ReactMarkdown children={textData?.text?.text ? textData.text.text : textData.text } />
            </div>
        </div>
    )
}

export default Text
