import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../components/common/navbar/Navbar'
import Footer from '../components/common/footer/Footer'
import Page from '../components/page/Page'
import ReactGA from "react-ga";

const PageLayout = ({ locales, content, pageNavbar, isHome, currentLocale }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [hasScrollClass, setHasScrollClass] = useState(false)
    let { slug } = useParams()
    ReactGA.pageview(window.location.pathname);

    const toggleMobileMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [slug])

    return (
        <>
            <Navbar 
                locales= { locales } 
                pageNavbar = {pageNavbar}
                content = {content}
                currentLocale={currentLocale}
                onToggleMobileMenu={() => toggleMobileMenu()}
                isMenuOpen={isMenuOpen} 
                hasScrollClass={hasScrollClass}/>
            <Page 
                content = {content} 
                isHome={isHome} 
                currentLocale={currentLocale} />
            <Footer />
        </>
    )
}

export default PageLayout
