import Helmet from 'react-helmet'

const Meta = ({ page, locale }) => {
    // console.log('meta tags: ', page, locale)
    const logo = 'https://cdp.lv/static/media/logo_new.f5c7e3c1.png';
    const metaImageURL = page.meta_image && page.is_published ? process.env.REACT_APP_CDP_API_URL + page.meta_image.formats.small.url : logo
    const metaTitle = page.name && page.is_published ? page.name : 'CDP'
    const metaDescription = page.meta_description && page.is_published ? page.meta_description : ''
    const metaUrl = page.is_published ? `${process.env.REACT_APP_CDP_PUBLIC_URL}/${locale}/${page.category ? page.category.slug + '/' + page.slug : page.slug}` : process.env.REACT_APP_CDP_PUBLIC_URL
    
    return (
        <Helmet htmlAttributes={{ lang : locale }} >
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />

            {/* Google / Search Engine Tags */}
            <meta itemprop="name" content={metaTitle} />
            <meta itemprop="description" content={metaDescription} />
            <meta itemprop="image" content={metaImageURL} />

            {/* Facebook Meta Tags */}
            <meta property="og:url" content={metaUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={metaImageURL} />

        </Helmet>
    )
}

export default Meta
