import {Switch, Route } from 'react-router-dom'
import PageLayout from '../layouts/PageLayout'

function Routes({ pageContent, pageNavbar, pageLocales, currentLocale }) {
    console.log("current locale in Routes: ", currentLocale)
    return (
        <>
         <Switch>
                <Route
                    exact 
                    path="/:locale" 
                    render={props => <PageLayout {...props} content={pageContent} pageNavbar={pageNavbar} isHome locales={pageLocales} currentLocale={currentLocale} />
                } />
                <Route 
                    exact 
                    path="/:locale/:slug" 
                    render={props => <PageLayout {...props} content={pageContent} pageNavbar={pageNavbar} locales={pageLocales} currentLocale={currentLocale} />
                } />
                <Route 
                    exact 
                    path="/:locale/:parentslug/:slug" 
                    render={props => <PageLayout {...props} content={pageContent} pageNavbar={pageNavbar} locales={pageLocales} currentLocale={currentLocale} />
                } />
         </Switch>
           
        </>
    )
}

export default Routes
